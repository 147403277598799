import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';

import Footer from './components/footer/footer';
import Navbar from './components/navbar/navbar';

import HomePage from './pages/home/HomePage';
import ResourcesPage from './pages/resources/ResourcesPage';
import ServicesPage from './pages/services/ServicesPage';
import KitsPage from './pages/kits/KitsPage';
import TechnologyPage from './pages/technology/TechnologyPage';
import ContactPage from './pages/contact/ContactPage';
import ServicePage from './pages/services/service/ServicePage';

import { service_cards } from './database/homepage/services-cards';
import { publications } from './database/publications';
import { resources } from './database/resources';
import { headers } from './database/headers';
import { services } from './database/services';
import { kits } from './database/kits';
import { createTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
// import AboutPage from './pages/about/AboutPage';

function App() {
  // MUI theming
  const theme = createTheme({
    palette: {
      primary: {
        // orange with white text
        main: '#FF7D03',
        contrastText: '#FFFFFF',
      },
      secondary: {
        // transparent, white text
        main: '#RRGGBB',
        contrastText: '#FFFFFF',
      },
    },
  });

  /** Functions to ensure navigation links go back to top of page */

  const [navBg, setNavBg] = useState(false);
  const isHome = true;

  const changeNavBg = (e) => {
    window.scrollY >= 100 ? setNavBg(true) : setNavBg(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNavBg);
    return () => {
      window.removeEventListener('scroll', changeNavBg);
    };
  }, []);

  /** If hash link, scroll to correct location */
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <main className='App'>
      <Navbar
        {...(isHome && navBg ? { backgroundColor: '#0D1932' } : {})}
        theme={theme}
      />
      <Routes>
        <Route
          path='/*'
          element={
            <HomePage
              service_cards={service_cards}
              publications={publications}
              theme={theme}
            />
          }
        />
        <Route
          path='/services/*'
          element={
            <ServicesPage headers={headers} services={services} theme={theme} />
          }
        />
        <Route
          path='/services/#hla'
          element={<ServicesPage headers={headers} theme={theme} />}
        />
        <Route
          path='/services/#kir'
          element={<ServicesPage headers={headers} theme={theme} />}
        />
        <Route
          path='/services/#micab'
          element={<ServicesPage headers={headers} theme={theme} />}
        />
        <Route
          path='/services/#fcr'
          element={<ServicesPage headers={headers} theme={theme} />}
        />
        {/* <Route
          path='/services/#chimerism'
          element={<ContactPage headers={headers} theme={theme} />}
        /> */}
        <Route
          path='/kits/*'
          element={<KitsPage headers={headers} kits={kits} />}
        />
        <Route
          path='/technology/*'
          element={<TechnologyPage headers={headers} />}
        />
        <Route
          path='/resources_&_publications/*'
          element={
            <ResourcesPage
              resources={resources}
              publications={publications}
              headers={headers}
              theme={theme}
            />
          }
        />
        <Route 
          path='/resources_&_publications/#publications'
          element={
            <ResourcesPage
              resources={resources}
              publications={publications}
              headers={headers}
              theme={theme}
            />
          }
        />
        {/* <Route 
          path='/about'
          element={
            <AboutPage
              headers={headers}
              theme={theme}
            />
          }
        /> */}
        <Route
          path='/contact/*'
          element={<ContactPage headers={headers} theme={theme} />}
        />
        <Route
          path='/contact/#order'
          element={<ContactPage headers={headers} theme={theme} />}
        />

        <Route
          path='/services/:id'
          element={<ServicePage headers={headers} services={services} />}
        />
      </Routes>
      <Footer />
    </main>
  );
}

export default App;
