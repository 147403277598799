import './footer.css';
import logo from '../../images/scisco-logo.png';
import clia from '../../images/homepage/clia.png';
import ashi from '../../images/homepage/ashi.png';
import { Link } from 'react-router-dom';
import { orange } from '@mui/material/colors';
import LocationOnIcon from '@mui/icons-material/LocationOnSharp';
import PhoneInTalkSharpIcon from '@mui/icons-material/PhoneInTalkSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  let date =  new Date().getFullYear();

  return (
    <>
      <div className='footer-container'>
        <div className='scisco-logo'>
          <img src={logo} className='footer-logo' alt='scisco logo'/>
          <p className='copyright copyright-nomobile'>
            © {date} Scisco Genetics, Inc.
            <br />
            All rights reserved.
          </p>
        </div>
        <div className='contact-types'>
          <section className='footer-contact-section'>
            <LocationOnIcon sx={{ color: orange[600] }} />
              <a className='footer-contact-info' href='https://goo.gl/maps/ejxQXfsv8g96uuLPA' target='_blank' rel='noreferrer'>1551 Eastlake Ave E
              <br />
              Suite 100, Seattle, WA 98102
              </a>
          </section>
          <section className='footer-contact-section'>
            <PhoneInTalkSharpIcon sx={{ color: orange[600] }} />
            <aside className='footer-contact-info'>
              <a href='tel: 888-827-9603' className='footer-link'>+1.888.827.9603</a>
              <br />
              Mon-Fri 9AM-5PM PST
            </aside>
          </section>
          <section className='footer-contact-section'>
            <EmailSharpIcon sx={{ color: orange[600] }} />
            <a href='mailto: info@sciscogenetics.com' className='footer-contact-info'>info@sciscogenetics.com</a>
          </section>
        </div>

        <div className='footer-links'>
          <Link to='/' className='footer-link' onClick={scrollToTop} >
            Home Page
          </Link>
          <Link to='/services' className='footer-link' onClick={scrollToTop} >
            Services
          </Link>
          <Link to='/kits' className='footer-link' onClick={scrollToTop} >
            Kits
          </Link>
          <Link to='/technology' className='footer-link' onClick={scrollToTop} >
            Technology
          </Link>
          <Link to='/resources_&_publications' className='footer-link' onClick={scrollToTop} >
            Resources & Publications
          </Link>
          <Link to='/contact' className='footer-link' onClick={scrollToTop} >
            Contact/Order
          </Link>
        </div>
        <section className='logos-container'>
          <img src={ashi} className='ashi-logo' alt='ashi logo' />
          <img src={clia} className='clia-logo' alt='clia logo' />
        </section>
        <p className='copyright copyright-mobile'>
          © {date} Scisco Genetics, Inc.
          All rights reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
